import(/* webpackMode: "eager" */ "/builds/idp-education/idp-connect/he-websites/next-js/he-websites/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/builds/idp-education/idp-connect/he-websites/next-js/he-websites/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/builds/idp-education/idp-connect/he-websites/next-js/he-websites/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/Farro-Bold.woff2\",\"variable\":\"--font-geist-sans\",\"weight\":\"100 900\"}],\"variableName\":\"farroBold\"}");
;
import(/* webpackMode: "eager" */ "/builds/idp-education/idp-connect/he-websites/next-js/he-websites/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/Inter-Bold.woff\",\"variable\":\"--font-geist-mono\",\"weight\":\"100 900\"}],\"variableName\":\"interBold\"}");
;
import(/* webpackMode: "eager" */ "/builds/idp-education/idp-connect/he-websites/next-js/he-websites/apps/pgs/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/idp-education/idp-connect/he-websites/next-js/he-websites/packages/lib/oneTrust/OneTrustCookieScript.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/idp-education/idp-connect/he-websites/next-js/he-websites/packages/lib/utlils/clicktrackerwrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/idp-education/idp-connect/he-websites/next-js/he-websites/packages/lib/utlils/GoogleOneTapPgs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/idp-education/idp-connect/he-websites/next-js/he-websites/packages/shared-components/layout-components/footer/footer-applink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/idp-education/idp-connect/he-websites/next-js/he-websites/packages/shared-components/layout-components/header/headercomponents.tsx");
